import {
  CouponStatusStatus as ReferralCouponStatus,
  ReferralReward,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { LoyaltyCoupon, Status as LoyaltyCouponStatus } from '@wix/ambassador-loyalty-v1-coupon/types';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';

export function getValidLoyaltyRewards(
  loyaltyRewards: LoyaltyReward[],
  loyaltyAccount: LoyaltyAccount,
  pointsBalance: number,
) {
  return loyaltyRewards.filter((reward) => {
    if (!reward.active) {
      return false;
    }

    const configs = [
      reward.couponReward?.percentage?.configsByTier,
      reward.couponReward?.fixedAmount?.configsByTier,
      reward.couponReward?.freeShipping?.configsByTier,
      reward.discountAmount?.configsByTier,
    ];

    return configs.some((config) =>
      config?.some(
        (tierConfig) =>
          tierConfig.tierId === loyaltyAccount.tier?.id && pointsBalance >= (tierConfig.costInPoints ?? 0),
      ),
    );
  });
}

export function getValidReferralRewards(referralRewards: ReferralReward[]) {
  return referralRewards.filter((reward) => reward.coupon?.status === ReferralCouponStatus.ACTIVE);
}

export function getValidLoyaltyCoupons(coupons: LoyaltyCoupon[]) {
  return coupons.filter((coupon) => coupon.status === LoyaltyCouponStatus.ACTIVE && !coupon.couponReference?.deleted);
}
