import { getSettingsValue } from '@wix/tpa-settings';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getLoyaltyAccount, getLoyaltyCoupons, getLoyaltyRewards, isReferralsAppInstalled } from '../../../utils';
import settingsParams, { DisplayChoice } from '../settingsParams';
import { getReferralRewards } from './getReferralRewards';
import { getValidLoyaltyCoupons, getValidLoyaltyRewards, getValidReferralRewards } from './rewards';

export const loadUserData = async (flowAPI: ControllerFlowAPI) => {
  const componentData = flowAPI.controllerConfig.config.publicData.COMPONENT || {};
  const displayChoice = getSettingsValue(componentData, settingsParams.displayChoice);
  let pointsBalance = 0;
  let rewardsAvailable = 0;

  if (displayChoice === DisplayChoice.PointsBalance) {
    const loyaltyAccount = await getLoyaltyAccount(flowAPI);
    pointsBalance = loyaltyAccount.points?.balance ?? 0;
  }

  if (displayChoice === DisplayChoice.Rewards) {
    const couponsPromise = getLoyaltyCoupons(flowAPI);
    const loyaltyRewardsPromise = getLoyaltyRewards(flowAPI);
    const referralsRewardsPromise = (await isReferralsAppInstalled(flowAPI))
      ? getReferralRewards(flowAPI)
      : Promise.resolve([]);
    const loyaltyAccountPromise = getLoyaltyAccount(flowAPI);

    const [coupons, loyaltyRewards, referralRewards, loyaltyAccount] = await Promise.all([
      couponsPromise,
      loyaltyRewardsPromise,
      referralsRewardsPromise,
      loyaltyAccountPromise,
    ]);

    pointsBalance = loyaltyAccount.points?.balance ?? 0;

    const validCoupons = getValidLoyaltyCoupons(coupons);
    const validReferralRewards = getValidReferralRewards(referralRewards);
    const validLoyaltyRewards = getValidLoyaltyRewards(loyaltyRewards, loyaltyAccount, pointsBalance);

    rewardsAvailable = validCoupons.length + validLoyaltyRewards.length + validReferralRewards.length;
  }

  return { pointsBalance, rewardsAvailable };
};
