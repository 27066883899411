import { DisplayChoice } from '../settingsParams';

export enum WidgetSettingsState {
  LoggedOut = 'loggedOut',
  LoggedIn = 'loggedIn',
}

export enum SettingsEvent {
  StateChange = 'stateChange',
  DisplayChoiceChange = 'displayChoiceChange',
  RefetchLoyaltyProgram = 'refetchLoyaltyProgram',
}

export interface SettingsEvents {
  [SettingsEvent.StateChange]: WidgetSettingsState;
  [SettingsEvent.DisplayChoiceChange]: DisplayChoice;
  [SettingsEvent.RefetchLoyaltyProgram]: null;
}
